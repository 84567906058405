import { Grid, ShowMoreButton } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { CardSmallGroupStoryblok, RichtextStoryblok } from '@/components';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ThemeProvider } from '@/contexts/theme';
import { getCorrespondingLegacyTheme } from '@/themes';
import { Themes } from '@/types';
import { cls, richTextToString } from '@/utils';
import { isOdd } from '@/utils/isOdd';
import { editableBlok } from '@/utils/storyblok';
import { useState } from 'react';
import { t } from 'ttag';
import { CardSmall, CardSmallProps } from '../CardSmall/CardSmall';
import styles from './CardSmallGroup.module.scss';
import { useCardSmallGridColumns } from './useCardSmallGridColumns';

interface BlokProps {
	blok: CardSmallGroupStoryblok;
	meta?: {
		opacity?: boolean;
		titleSize: 'h3' | 'h4' | 'h5';
		theme?: Themes['theme'];
	};
}

interface Props {
	cards: CardSmallProps[];
	className?: string;
	groupGrid?: boolean;
	cardButtonVariant?: 'filled' | 'outlined' | 'text';
	layout?: 'default' | 'center';
	theme?: Themes['theme'];
	groupLinkButton?: LinkButtonProps;
	gapSize?: 'md' | 'sm';
	borderRadius?: '3-5';
	_editable?: string;
}

export type { Props as CardSmallGroupProps };

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	cards: blok?.cards?.map((card) =>
		CardSmall.blokProps({
			blok: card,
			meta: {
				...meta,
				titleSize: blok.titleSize,
				opacity: meta?.opacity,
				buttonVariant: blok?.cardButtonVariant,
			},
		}),
	),
	groupLinkButton: blok.groupLinkButton?.[0] && LinkButton.blokProps({ blok: blok.groupLinkButton[0] }),
	theme: meta?.theme,
	groupGrid: blok.groupGrid,
	_editable: blok._editable,
	cardButtonVariant: blok.cardButtonVariant,
});

const hasLongText = (cards: CardSmallProps[]): boolean => {
	return cards.some((card) => {
		const text =
			card.text && typeof card.text === 'string' ? card.text : richTextToString(card.text as RichtextStoryblok);
		return text?.length > 100;
	});
};

export const CardSmallGroup: Blok<Props, BlokProps> = ({
	cards,
	className,
	groupGrid = false,
	groupLinkButton,
	cardButtonVariant,
	theme,
	gapSize = 'md',
	borderRadius,
	_editable,
}) => {
	const [showRows, setShowRows] = useState(2);
	const { setTargetInteraction } = useImpressionTracking();

	const nrOfCards = cards?.length ?? 0;
	const columns = useCardSmallGridColumns(nrOfCards, groupGrid);
	let cardsPerRow;
	if (groupGrid) {
		cardsPerRow = 4;
	} else {
		cardsPerRow = nrOfCards === 5 || nrOfCards % 3 === 0 ? 3 : 4;
	}
	const nrOfRows = nrOfCards / cardsPerRow;
	const groupLinkButtonVariant = cardButtonVariant === 'text' ? 'filled' : 'text';
	const cardsWithLongText = hasLongText(cards);
	const cardLayout = cardsWithLongText || nrOfCards > 6 ? 'default' : 'center';
	const hasHiddenCards = showRows < nrOfRows;
	const showMoreButton = nrOfCards > 8;
	const animateShowMore = showMoreButton && showRows === 3;

	const onShowMore = () => {
		setShowRows(showRows < nrOfRows ? showRows + 1 : showRows);
	};

	const onShowLess = () => {
		setShowRows(showRows - 1);
	};

	return (
		<div>
			<Grid
				columns={columns}
				className={cls(
					nrOfCards === 2 && styles.center,
					isOdd(nrOfCards) && styles.oddNrOfCards,
					styles[`nr-of-grid-items--${nrOfCards}`],
					groupGrid && nrOfCards === 4 && styles.group,
					styles[`gap-size--${gapSize}`],
					className,
				)}
				{...editableBlok({ _editable })}
			>
				{cards?.slice(0, cardsPerRow * showRows).map((card, index) => (
					<Grid.Item
						key={card._uid}
						className={cls(styles.gridItem, index >= cardsPerRow * 2 && animateShowMore && styles.fadeIn)}
					>
						<CardSmall {...card} layout={cardLayout} borderRadius={borderRadius} />
					</Grid.Item>
				))}
			</Grid>

			{showMoreButton && (
				<ThemeProvider theme={getCorrespondingLegacyTheme(theme)}>
					<ShowMoreButton
						onClick={hasHiddenCards ? onShowMore : onShowLess}
						icon={hasHiddenCards ? 'chevron-down' : 'chevron-up'}
						iconPosition={hasHiddenCards ? 'bottom' : 'top'}
					>
						{hasHiddenCards ? t`Visa fler` : t`Visa mindre`}
					</ShowMoreButton>
				</ThemeProvider>
			)}

			{groupLinkButton && (
				<ThemeProvider theme={getCorrespondingLegacyTheme(theme)}>
					<div className={styles.btnPosition}>
						<LinkButton
							{...groupLinkButton}
							variant={groupLinkButtonVariant}
							onClick={() => {
								setTargetInteraction({ contentTarget: 'default' });
							}}
						/>
					</div>
				</ThemeProvider>
			)}
		</div>
	);
};
CardSmallGroup.blokProps = blokProps;
