import { Integrator } from '@/contexts/Integration';

export const calculateIntegratorScore = (integrator: Integrator): number => {
	return [
		(i: Integrator) => (i.companyLogoUrl ? 1 : -1),
		(i: Integrator) => (i.about ? 1 : -1),
		(i: Integrator) => (i.about?.length > 50 ? 1 : -1),
		(i: Integrator) => (i.contactEmail || i.contactPhone || i.contactHomepage ? 1 : -1),
	].reduce((score, rule) => score + rule(integrator), 1);
};
