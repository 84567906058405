import React, { PropsWithChildren, createContext, useContext } from 'react';
import { CustomerRating, Integration, IntegrationClientType } from './types';

/**
 * An Integration from App Market
 */
export const useIntegration = () => {
	return useContext(Context);
};

interface ContextProps {
	integrations?: Integration[];
	integration?: Integration;
	ratings?: CustomerRating[];
}
const Context = createContext<ContextProps>({
	integrations: [],
	integration: {
		appId: '',
		integrationId: '',
		redirectUri: '',
		appName: '',
		summaryTitle: '',
		tenantId: '',
		summary: '',
		about: '',
		howTo: '',
		readMoreUrl: '',
		priceModel: { clientType: IntegrationClientType.BuyableApp, price: '' },
		urlSlug: '',
		media: {
			icon: undefined,
			heroes: [],
		},
		scopes: null,
		categories: [],
		ratings: {
			averageScore: 0,
			numberOfRatings: 0,
			weightedAverageScore: 0,
		},
		contactInformation: {
			companyName: '',
			email: '',
			phone: '',
		},
		uniqueSellingPoints: {
			title: '',
			points: [],
		},
		customerReviews: [],
		insights: {
			numberOfUsersRange: '',
		},
		tags: [],
	},
	ratings: [],
});

interface Props {
	integrations?: Integration[];
	integration?: Integration;
	ratings?: CustomerRating[];
}

export const IntegrationProvider: React.FC<PropsWithChildren<Props>> = ({
	children,
	integrations,
	integration,
	ratings,
}) => {
	const value = {
		integrations,
		integration,
		ratings,
	};
	return <Context.Provider value={value}>{children}</Context.Provider>;
};

Context.displayName = 'Integration Context';
