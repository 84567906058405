import { Card, Heading, IconPlus, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { OffertaPopUpButton, OffertaPopUpButtonProps } from '@/bloks/Button/OffertaPopUpButton';
import { renderRichText } from '@/bloks/RichText';
import { CardMediumStoryblok, RichtextStoryblok } from '@/components';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { useThemeContext } from '@/contexts/theme';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { IconPlusName } from 'icons';
import styles from './CardMedium.module.scss';

interface BlokProps {
	blok: CardMediumStoryblok;
	meta?: {
		nrOfCards?: number;
		noBackground?: boolean;
	};
}

const blokProps = ({ blok, meta }: BlokProps): CardMediumProps => ({
	image: blok.image,
	icon: blok.icon?.[0]?.name,
	smallTitle: blok?.smallTitle,
	title: blok.title,
	text: blok.text,
	linkButton: blok?.linkButton?.[0]?.link && LinkButton.blokProps({ blok: blok.linkButton[0] }),
	offertaPopUpButton:
		blok?.offertaPopUpButton?.[0] && OffertaPopUpButton.blokProps({ blok: blok.offertaPopUpButton[0] }),
	nrOfCards: meta?.nrOfCards,
	noBackground: meta?.noBackground,
	_editable: blok._editable,
	_uid: blok._uid,
});

export interface CardMediumProps {
	image?: ImageAssetProps;
	icon?: IconPlusName;
	smallTitle?: string;
	title: string;
	text: string | RichtextStoryblok;
	linkButton?: LinkButtonProps;
	offertaPopUpButton?: OffertaPopUpButtonProps;
	buttonVariant?: 'filled' | 'text';

	/**
	 * Passed from parent group to determent image height
	 */
	nrOfCards?: number;
	largeImage?: boolean;
	className?: string;
	noBackground?: boolean;
	titleSize?: 'h3' | 'h4' | 'h5';
	_editable?: string;
	_uid?: string;
}

export const CardMedium: Blok<CardMediumProps, BlokProps> = ({
	image,
	icon,
	smallTitle,
	title,
	text,
	linkButton,
	offertaPopUpButton,
	buttonVariant = 'filled',
	nrOfCards = 1,
	largeImage = false,
	noBackground = false,
	titleSize = 'h5',
	className,
	_editable,
}) => {
	const { applyChildTheme, theme } = useThemeContext();
	const { setTargetInteraction } = useImpressionTracking();
	const hasImage = !!image?.filename;
	const addBorder = !applyChildTheme && theme === 'white' && !hasImage;
	const roundedImage = !applyChildTheme && theme !== 'white';

	return (
		<Card
			{...editableBlok({ _editable })}
			className={cls(
				styles.card,
				hasImage && (roundedImage ? styles.roundedImage : styles.roundedImageTop),
				largeImage && styles.largeImage,
				(applyChildTheme || addBorder) && styles.childTheme,
				className,
			)}
			border={addBorder}
			padding="none"
			noBackground={noBackground}
		>
			{hasImage && (
				<div className={cls(styles.image, !largeImage && styles[`image-height--${nrOfCards}`])}>
					<ImageAsset absolute asset={image} objectFit="cover" fill objectPosition="center center" filters="smart" />
				</div>
			)}

			{!hasImage && icon && (
				<div className={styles.iconWrapper}>
					<IconPlus name={icon} size="lg" />
				</div>
			)}

			<div className={styles.textContainer}>
				{smallTitle && <Heading as="div" size="h6" title={smallTitle} marginBottom="md" />}

				{title && <Heading as="div" size={titleSize} title={title} />}

				{typeof text === 'string' ? (
					<Text marginBottom="md">{text}</Text>
				) : (
					renderRichText(text, { textMarginBottom: 'md' })
				)}

				{linkButton && (
					<div className={styles.buttonContainer}>
						<LinkButton
							{...linkButton}
							variant={buttonVariant}
							onClick={() => {
								setTargetInteraction({ contentTarget: (linkButton.children as string) ?? '' });
							}}
						/>
					</div>
				)}
				{!linkButton && offertaPopUpButton && (
					<div className={styles.buttonContainer}>
						<OffertaPopUpButton
							{...offertaPopUpButton}
							onClick={() => {
								setTargetInteraction({ contentTarget: (offertaPopUpButton.buttonText as string) ?? '' });
							}}
						/>
					</div>
				)}
			</div>
		</Card>
	);
};

CardMedium.blokProps = blokProps;
