import { Card, CustomIcon, Heading } from '@/atoms';
import { CardKeyFiguresStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { CustomIconName } from 'icons/types';
import styles from './CardKeyFigures.module.scss';

interface BlokProps {
	blok: CardKeyFiguresStoryblok;
	meta?: {
		operator?: string;
	};
}

const blokProps = ({ blok, meta }: BlokProps): CardKeyFiguresProps => ({
	icon: blok.icon as CustomIconName,
	title: blok.title,
	prefix: blok?.prefix || undefined,
	figure: Number(blok.figure),
	unit: blok.unit,
	previousFigure: Number(blok?.previousFigure),
	_editable: blok._editable!,
	operator: meta?.operator,
});

export interface CardKeyFiguresProps {
	icon: CustomIconName;
	title: string;
	prefix?: CardKeyFiguresStoryblok['prefix'];
	figure?: number;
	unit: string;
	previousFigure?: number;
	operator?: string;
	_editable?: string;
}

export function CardKeyFigures({
	icon,
	title,
	prefix,
	figure,
	unit,
	previousFigure,
	operator,
	_editable,
}: CardKeyFiguresProps) {
	const iconName = icon;

	return (
		<Card
			className={cls(styles.card, styles.CardKeyFigures)}
			padding="lg"
			{...editableBlok({ _editable })}
			noBackground
		>
			<div className={styles.container}>
				{icon && <CustomIcon className={styles.icon} name={iconName} size="3x" />}
				{title && <Heading as="div" size="h6" title={title} className={styles.title} marginBottom="xs" />}
				{figure && (
					<div className={styles.figure}>
						{prefix}
						{figure} {unit}
					</div>
				)}
				{previousFigure ? (
					<span className={styles.previousFigure}>
						{prefix ? `(${prefix}${previousFigure})` : `(${previousFigure})`}
					</span>
				) : (
					<span className={styles.previousFigure} />
				)}
			</div>
			{operator && <div className={styles.operator}>{operator}</div>}
		</Card>
	);
}

CardKeyFigures.blokProps = blokProps;
