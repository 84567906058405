import { Card, Heading } from '@/atoms';
import { CardFiveStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { LinkButton } from '../Button';
import { renderRichText } from '../RichText';
import styles from './CardFive.module.scss';

interface Props {
	blok: CardFiveStoryblok;
	meta?: {
		isLastCard?: boolean;
		isFirstCard?: boolean;
	};
}

/**
 * CardFive component (The one without a form)
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=326%3A11065
 */
export const CardFive: React.FC<Props> = ({ blok, meta = {} }) => {
	const buttonVariant = blok?.buttonVariant ?? 'filled';

	return (
		<Card
			{...editableBlok(blok)}
			className={cls(
				styles.container,
				styles.card,
				meta?.isFirstCard && styles.firstCard,
				meta?.isLastCard && styles.lastCard,
			)}
		>
			<div className={styles.content}>
				{blok.smallTitle && (
					<Heading
						as="div"
						size="h6"
						title={blok.smallTitle}
						data-testid="card-five-small-title-h6"
						marginBottom="xl"
					/>
				)}
				{blok?.title && (
					<Heading as="div" size="h4" title={blok.title} marginBottom="xl" data-testid={'card-five-header'} />
				)}
				<div className={styles.text}>{renderRichText(blok.text)}</div>
			</div>

			<footer className={styles.footer}>
				{blok.linkButtons?.map((button) => (
					<LinkButton key={button._uid} {...LinkButton.blokProps({ blok: button })} variant={buttonVariant} />
				))}
			</footer>
		</Card>
	);
};
