import { Button, Card, Divider, Heading, Icon, Price, Sticker, StickerProps, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { ProductUsp, ProductUspGroup } from '@/bloks/Usp';
import { ProductUspProps } from '@/bloks/Usp/ProductUsp/ProductUsp';
import {
	PriceCardV2Storyblok,
	ProductCatalogItemStoryblok,
	ProductCatalogPackageItemStoryblok,
	ProductUspStoryblok,
} from '@/components';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { PriceStructure, Product, useMultipleProducts } from '@/contexts/products';
import { ThemeProvider } from '@/contexts/theme';
import { SBPackageProduct, SBProduct } from '@/types';
import { ISbStoryData } from '@/types/storyblok';
import { cls } from '@/utils';
import { handlePriceCardClickEvent } from '@/utils/piwik';
import { buildSBPackageProduct, buildSBProduct } from '@/utils/product';
import { usePathname } from 'next/navigation';
import React, { useRef } from 'react';
import { t } from 'ttag';
import styles from './PriceCardV2.module.scss';

type PackageProductsNamesProps = {
	products: Product[];
};
const PackageProductsNames: React.FC<PackageProductsNamesProps> = ({ products }) => {
	return (
		<div className={styles.packageProductNames}>
			{products
				?.filter(({ addOnProducts }) => !addOnProducts?.length)
				?.map((product) => {
					const isLevFaktura = product?.name === 'Leverantörsfakturaattest';
					const productName = isLevFaktura ? t`Levfakturaattest` : product.name;

					return (
						<div key={product.id}>
							<Text marginBottom="none">{productName}</Text>
						</div>
					);
				})}
		</div>
	);
};

export const getPriceStructure = (productCatalogData: Product[] | null): PriceStructure => {
	const combinedPrice = productCatalogData?.reduce((acc, product) => acc + product?.priceStructure[12][0], 0);

	return {
		12: { 0: combinedPrice ? combinedPrice : 0 },
	};
};

export const getProductIds = (product: SBProduct | SBPackageProduct): string[] => {
	const combinedProductIds = product?.products?.map((p) => p.slug) ?? [];
	return combinedProductIds.length > 0 ? combinedProductIds : [product?.slug ?? ''];
};

interface BlokProps {
	blok: PriceCardV2Storyblok;
	meta: { primary: boolean; zone: number | undefined; showMore: boolean; toggleShowMore: () => void };
}

const blokProps = ({ blok, meta }: BlokProps): Props => {
	let product = buildSBProduct(blok.product as unknown as ISbStoryData<ProductCatalogItemStoryblok>);

	if (product.component == 'ProductCatalogPackageItem') {
		product = buildSBPackageProduct(blok.product as unknown as ISbStoryData<ProductCatalogPackageItemStoryblok>);
	}

	const productLinkButton = product?.linkButtons?.[0];
	const linkButton = blok?.linkButton?.[0];
	const sticker = blok?.sticker && blok?.sticker[0];

	const uspStories = blok.usps as unknown as ISbStoryData<ProductUspStoryblok>[];
	const antiUspStories = blok.antiUsps as unknown as ISbStoryData<ProductUspStoryblok>[];

	return {
		sticker: sticker && {
			title: sticker?.text,
			icon: sticker?.icon?.[0]?.name,
		},
		smallTitle: blok?.smallTitle,
		setCustomProductDescription: blok?.setCustomProductDescription,
		description: blok?.description,
		packageProductTitle: blok?.packageProductTitle,
		termsAndCondition: blok?.termsAndCondition,
		crossedOutPrice: blok?.crossedOutPrice,
		usps: uspStories?.map((usp: ISbStoryData<ProductUspStoryblok>) => ProductUsp.blokProps({ blok: usp.content })),
		antiUsps: antiUspStories?.map((usp: ISbStoryData<ProductUspStoryblok>) =>
			ProductUsp.blokProps({ blok: usp.content }),
		),
		product,
		button: linkButton ? LinkButton.blokProps({ blok: linkButton }) : productLinkButton,
		zone: meta?.zone,
		primary: meta?.primary ?? false,
		toggleShowMore: meta.toggleShowMore,
		showMore: meta.showMore,
		termsAndConditionHeader: blok?.termsAndConditionHeader,
	};
};

interface Props {
	sticker?: StickerProps;
	smallTitle?: string;
	setCustomProductDescription?: boolean;
	description?: string;
	packageProductTitle?: string;
	termsAndCondition?: string;
	crossedOutPrice?: boolean;
	usps: ProductUspProps[];
	antiUsps?: ProductUspProps[];
	product: SBProduct | SBPackageProduct;
	button?: LinkButtonProps;
	zone?: number | undefined;
	primary: boolean;
	showMore: boolean;
	toggleShowMore: () => void;
	termsAndConditionHeader?: string;
}

export const PriceCardV2: Blok<Props, BlokProps> = ({
	sticker,
	smallTitle,
	setCustomProductDescription,
	description,
	packageProductTitle,
	termsAndCondition,
	crossedOutPrice,
	usps,
	antiUsps,
	product,
	button,
	zone,
	primary,
	showMore,
	toggleShowMore,
	termsAndConditionHeader,
}) => {
	const theme = primary ? 'primary' : 'secondary';
	const isCombinedProductsPackage = product.products;
	const pathname = usePathname();

	const productCatalogData = useMultipleProducts(getProductIds(product));
	const packageProducts =
		productCatalogData &&
		productCatalogData[0]?.packageProducts?.sort((a, b) => {
			return Number(a.id) - Number(b.id);
		});

	const productDescription = product.description ? product.description : '';
	const descriptionText = setCustomProductDescription ? description : productDescription;
	const descriptionTextRef = useRef<HTMLDivElement>(null);
	const shouldShowExpandContentButton =
		descriptionText || packageProducts || isCombinedProductsPackage || termsAndCondition;

	const linkButton = product?.readMoreButton?.[0];

	const { setTargetInteraction } = useImpressionTracking();

	const getProductName = () => {
		if (product.name === 'Leverantörsfakturaattest') {
			return `Leverantörsfaktura\u00ADattest`;
		}
		return product.name;
	};

	if (!productCatalogData) {
		return null;
	}

	return (
		<ThemeProvider theme={theme}>
			<div className={cls(styles.outerContainer, !primary && styles.addSpace)}>
				<Card
					className={cls(styles.container, primary ? styles.primary : styles.secondary)}
					data-testid={`price-card-${productCatalogData[0]?.id ?? product.slug}`}
					padding={'lg'}
				>
					{sticker?.title && <Sticker title={sticker.title} icon={sticker?.icon} className={styles.priceCardSticker} />}

					{smallTitle && (
						<Text marginBottom="none" className={styles.bold} as="div">
							{smallTitle}
						</Text>
					)}
					{product && (
						<Heading
							as="div"
							exo2={true}
							size={'h3'}
							title={getProductName()}
							marginBottom="md"
							className={styles.productHeading}
						/>
					)}

					<ProductUspGroup usps={usps} antiUsps={antiUsps} />

					{!showMore && shouldShowExpandContentButton && (
						<div className={styles.centerButton}>
							<Button variant="text" className={styles.showContentButton} onClick={() => toggleShowMore()}>
								{t`Visa mer`}
								<Icon name={'chevron-down'} size="xs" className={styles.showContentIcon} />
							</Button>
						</div>
					)}

					{showMore && shouldShowExpandContentButton && (
						<>
							<Divider
								className={cls(styles.line, primary && styles.primaryLine)}
								color={primary ? '--text-dark' : '--text-mint-green'}
							/>
							<div ref={descriptionTextRef} className={cls(styles.descriptionText, !showMore && styles.lineClamp)}>
								<Text marginBottom={'none'}>{descriptionText}</Text>
							</div>
							{(packageProducts || isCombinedProductsPackage) && packageProductTitle && (
								<Text marginBottom="2xs" as="div" className={styles.bold}>
									{packageProductTitle}
								</Text>
							)}

							{packageProducts && packageProducts.length && <PackageProductsNames products={packageProducts} />}

							{isCombinedProductsPackage && isCombinedProductsPackage.length > 0 && (
								<PackageProductsNames products={productCatalogData} />
							)}

							{termsAndConditionHeader && (
								<Heading as={'div'} marginBottom="2xs" size="h6" title={termsAndConditionHeader} />
							)}

							{termsAndCondition && (
								<Text marginBottom="md" className={styles.termsText}>
									{termsAndCondition}
								</Text>
							)}

							<div className={styles.centerButton}>
								<Button variant="text" className={styles.showContentButton} onClick={() => toggleShowMore()}>
									{t`Visa mindre`}
									<Icon
										color={primary ? '--primary-green' : '--bg-white'}
										name={'chevron-up'}
										size="xs"
										className={styles.showContentIcon}
									/>
								</Button>
							</div>
						</>
					)}

					<div className={styles.priceAndBtnContainer}>
						<div className={styles.price}>
							{crossedOutPrice && packageProducts && (
								<Price
									crossedOutPrice={true}
									priceStructure={getPriceStructure(packageProducts)}
									priceText={t`kr/mån`}
									size={'h3'}
								/>
							)}
							<Price
								className={cls(primary ? styles.customPriceLargeFont : styles.customPriceMediumFont)}
								priceStructure={
									product.price
										? { 3: undefined, 12: { 0: parseFloat(product.price) } }
										: getPriceStructure(productCatalogData)
								}
								priceText={product?.customPriceText || t`kr/mån`}
								size={'custom'}
								showFrom={!product?.hideFrom}
							/>
						</div>
						{button && (
							<LinkButton
								onClick={() => {
									handlePriceCardClickEvent({
										productName: product.name,
										productId: product.slug,
										zone,
										buttonVariant: 'filled',
										buttonText: button.children as string,
									});
									setTargetInteraction({ contentTarget: button.children as string });
								}}
								{...button}
								variant={theme == 'primary' ? 'filled' : 'outlined'}
								size={theme == 'primary' ? 'large' : 'medium'}
								className={styles.button}
								queryString={`pageToCheckout=${pathname}`}
							/>
						)}
						{linkButton && (
							<div className={styles.readMoreButton}>
								<LinkButton
									{...linkButton}
									variant="text"
									onClick={() => {
										setTargetInteraction({ contentTarget: linkButton.children as string });
									}}
								/>
							</div>
						)}
					</div>
				</Card>
			</div>
		</ThemeProvider>
	);
};

PriceCardV2.blokProps = blokProps;
