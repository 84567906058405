import { Card, Grid, Heading, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinksCommon } from '@/bloks/Other/LinksCommon';
import { renderRichText } from '@/bloks/RichText';
import { CardLargeLinksHighStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import React, { ReactElement } from 'react';
import styles from './CardLargeLinksHigh.module.scss';

interface BlokProps {
	blok: CardLargeLinksHighStoryblok;
	meta?: {
		noPadding?: boolean;
	};
	className?: string;
}
const blokProps = ({ blok, meta, className }: BlokProps): Props => ({
	title: blok?.title,
	smallTitle: blok?.smallTitle,
	text: blok?.text,
	published: blok?.published,
	links: <LinksCommon links={blok?.links} />,
	_editable: blok._editable,
	noPadding: meta?.noPadding ?? false,
	className,
});

interface Props {
	_editable?: string;
	title?: string;
	smallTitle?: string;
	text?: string;
	links?: ReactElement;
	published?: string;
	className?: string;
	noPadding?: boolean;
}

export const CardLargeLinksHigh: Blok<Props, BlokProps> = ({
	_editable,
	title,
	smallTitle,
	text,
	links,
	noPadding = false,
	published,
	className,
}) => {
	return (
		<Card {...editableBlok({ _editable })} padding={noPadding ? 'none' : 'xl'} className={className}>
			{title && <Heading className={styles.title} as="div" size="h3" title={title} marginBottom="xs" />}
			{published && <Text className={cls(styles.published, styles.text)}>{published}</Text>}

			<Grid columns={{ base: 1, md: 2, lg: '6fr 4fr' }} colGap="xl" rowGap="xl">
				<Grid.Item>
					<div className={styles.contentWrapper}>
						{smallTitle && (
							<Heading className={styles.smallTitle} as="div" size="h5" title={smallTitle} marginBottom="md" />
						)}
						{text && (
							<Text className={styles.text} as="div">
								{renderRichText(text)}
							</Text>
						)}
					</div>
				</Grid.Item>
				{links && <Grid.Item>{links}</Grid.Item>}
			</Grid>
		</Card>
	);
};

CardLargeLinksHigh.blokProps = blokProps;
