import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { useTheme } from '@/contexts/theme';
import Link from 'next/link';
import classNames from 'classnames';
import styles from './Card.module.scss';

export interface CardProps
	extends Pick<React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLDivElement>, 'title'> {
	children: React.ReactNode;
	/**
	 * Padding in the card
	 *
	 * 		none	0px		0rem
	 *		xs		8px		0.5rem
	 *		sm		12px	0.75rem
	 *		md		16px	1rem
	 *		lg		24px	1.5rem
	 *		xl		32px	2rem
	 *
	 */
	padding?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'custom';

	/**
	 * If card should have border-radius or not.
	 */
	rounded?: boolean;

	borderRadius?: '3-5' | 'card';

	/**
	 * If card should have border.
	 */
	border?: boolean;

	/**
	 * Layout sets the content inside the card should be aligned left or centered.
	 */
	layout?: 'default' | 'center';

	/**
	 * Use transparent background on card
	 */
	noBackground?: boolean;

	/**
	 * Can be used to make the entire card clickable as a link
	 */
	cardLink?: string;

	className?: string;
}

/**
 * A simple card
 */
export const Card: React.FC<CardProps> = ({
	padding = 'xl',
	children,
	borderRadius = 'card',
	border = false,
	rounded = true,
	layout = 'default',
	className,
	noBackground = false,
	cardLink,
	...rest
}) => {
	const themeInfo = useTheme();
	const { setTargetInteraction } = useImpressionTracking();

	const card = (
		<div
			className={classNames(
				styles.container,
				rounded && styles[`border-radius--${borderRadius}`],
				padding && padding !== 'custom' && styles[`padding--${padding}`],
				styles[`border--${border}`],
				styles[`layout--${layout}`],
				themeInfo.styles.card,
				noBackground && styles.noBackground,
				className,
			)}
			{...rest}
		>
			{children}
		</div>
	);

	return cardLink ? (
		<Link
			href={cardLink}
			onClick={() => {
				setTargetInteraction({ contentTarget: 'default' });
			}}
		>
			{card}
		</Link>
	) : (
		card
	);
};
