import { Card, Heading, Text } from '@/atoms';
import { CustomerReview, useIntegration } from '@/contexts/Integration';
import { ThemeProvider } from '@/contexts/theme';
import { getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import React from 'react';
import styles from './CustomerReviews.module.scss';
import { IntegrationCarousel } from './IntegrationCarousel';

const CustomerReviews: React.FC = () => {
	const { integration } = useIntegration();
	const themeInfo = getThemeInfo('darkGreen');

	if (!integration?.customerReviews) return null;

	const customerReviews = integration.customerReviews.filter((review) => review.review);

	const carouselItems = customerReviews.map((review: CustomerReview, index: number) => {
		return (
			<ThemeProvider theme="darkGreen" key={`customerReview-${index}`}>
				<Card className={cls(styles.reviewItem, themeInfo.styles.bgColor)} padding="2xl">
					<Heading as="h3" title={'Så här säger kunderna'} className={styles.reviewHeading} />
					<div className={cls(styles.reviewText, themeInfo.styles.textColor)}>
						<div className={styles.review}>{review.review}</div>
						<Text>{review.role}</Text>
						<Text>- {review.companyName}</Text>
					</div>
				</Card>
			</ThemeProvider>
		);
	});

	return (
		<IntegrationCarousel carouselItems={carouselItems} showNavigation={customerReviews.length > 1} singleSlide={true} />
	);
};

export default CustomerReviews;
