import { BrandStripes, ContainerFluid, Grid, Heading, Image, Section } from '@/atoms';
import { Integration, Integrator, useIntegration } from '@/contexts/Integration';
import { useIntegrator } from '@/contexts/Integration/IntegratorProvider';
import { useServerPageConfig } from '@/contexts/config';
import { IntegrationCard } from '../NavigationPageIntegrations/IntegrationCard/IntegrationCard';
import ContactInformation from './Components/ContactInformation';
import styles from './IntegratorListView.module.scss';
import { calculateIntegratorScore } from './functions/calculateIntegratorScore';

const IntegratorInfo: React.FC<{ integrator: Integrator }> = ({ integrator }) => {
	const { appsHost: host } = useServerPageConfig();

	return (
		<Grid.Item
			key="contact-information"
			rowSpan={{ base: 2 }}
			rowStart={{ base: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
			colStart={{ base: 1, sm: 1, md: 2, lg: 2, xl: 3 }}
			colSpan={{ base: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
			className={styles.integratorInformation}
		>
			<div className={styles.logo}>
				{integrator?.companyLogoUrl && (
					<Image
						src={`${host}${integrator?.companyLogoUrl}`}
						fill={false}
						width={800}
						height={800}
						alt={`Logotyp för ${integrator?.companyName}`}
						className={styles.integratorLogo}
					/>
				)}
			</div>
			<div className={styles.info}>
				<Heading as="h2" size="h5" title={`Om ${integrator.companyName}`} />
				{integrator?.about && <p>{integrator?.about}</p>}
				{integrator?.successStory && <p>{integrator?.successStory}</p>}

				<ContactInformation integrator={integrator} showAccessRights={false} className={styles.contactInformation} />
			</div>
		</Grid.Item>
	);
};

export const IntegratorListView: React.FC = () => {
	const { integrations } = useIntegration();
	const { integrator } = useIntegrator();

	const uniqueCategories = [
		...new Map(integrations?.flatMap(({ categories }) => categories).map((c) => [c.categoryId, c])).values(),
	];

	const categories = uniqueCategories.map(({ categoryNameSv }) => categoryNameSv).join(', ');

	const integrationsList = integrations?.map((integration: Integration) => (
		<Grid.Item key={integration.appId}>
			<IntegrationCard integration={integration} />
		</Grid.Item>
	));

	if (!integrationsList) return null;

	if (calculateIntegratorScore(integrator) > 2) {
		integrationsList.splice(2, 0, <IntegratorInfo key="integrator-info" integrator={integrator} />);
	}

	return (
		<ContainerFluid marginBottom="6xl">
			<Section paddingX={{ base: 'md', xl: '4xl' }} paddingY={{ base: '5xl' }} className={styles.integratorHeader}>
				<BrandStripes />
				<Heading as="div" size="h5" title={categories} />
				<Heading
					as="h1"
					title={`${(integrations ?? []).length > 1 ? 'Smarta' : 'Smart'} ${
						(integrations ?? []).length > 1 ? 'kopplingar' : 'koppling'
					} från ${integrator.companyName}`}
				/>
			</Section>

			<Section paddingX={{ base: 'md', xl: '4xl' }} paddingY={{ base: 'xl' }}>
				<Grid columns={{ base: 1, md: 2, xl: 3 }} colGap="xl" rowGap="xl">
					{integrationsList}
				</Grid>
			</Section>
		</ContainerFluid>
	);
};
