import { Button, Card, Heading, Icon, IconButton, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { Video, VideoProps } from '@/bloks/Other';
import { CardMediumVideoStoryblok } from '@/components';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { useThemeContext } from '@/contexts/theme';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import Vimeo from '@vimeo/player';
import { useEffect, useRef, useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { t } from 'ttag';
import styles from './CardMediumVideo.module.scss';

interface BlokProps {
	blok: CardMediumVideoStoryblok;
}

const blokProps = ({ blok }: BlokProps): CardMediumVideoProps => ({
	image: blok.image,
	video: blok.video?.[0] && Video.blokProps({ blok: blok.video[0] }),
	title: blok.title,
	text: blok.text,
	linkButton: blok.linkButton?.[0] && LinkButton.blokProps({ blok: blok.linkButton[0] }),
	_editable: blok._editable,
});

export interface CardMediumVideoProps {
	image: ImageAssetProps;
	video: VideoProps;
	title: string;
	text?: string;
	linkButton?: LinkButtonProps;
	nrOfCards?: number;
	_editable?: string;
}

export const CardMediumVideo: Blok<CardMediumVideoProps, BlokProps> = ({
	image,
	video,
	title,
	text,
	linkButton,
	nrOfCards = 2,
	_editable,
}) => {
	const { applyChildTheme } = useThemeContext();
	const [showVideo, setShowVideo] = useState(false);
	const vimeoIframeRef = useRef<HTMLIFrameElement>(null);
	const playerRef = useRef<Vimeo | null>(null);

	useEffect(() => {
		if (vimeoIframeRef.current) {
			const player = new Vimeo(vimeoIframeRef.current);
			playerRef.current = player;
		}
	}, []);

	const closeVideo = () => {
		playerRef?.current?.pause();
		setShowVideo(false);
	};

	const { setTargetInteraction } = useImpressionTracking();

	return (
		<>
			<RemoveScroll enabled={showVideo}>
				<div className={cls(styles.overlay, showVideo && styles.showVideo)}>
					<div className={styles.videoWrapper}>
						<IconButton
							iconName="close"
							className={styles.closeIcon}
							onClick={closeVideo}
							aria-label={t`Stäng video`}
							iconColor="--text-dark"
							iconSize="2x"
						/>

						{video && <Video {...video} forwardedRef={vimeoIframeRef} />}
					</div>
				</div>
			</RemoveScroll>

			<Card
				{...editableBlok({ _editable })}
				className={cls(styles.card, applyChildTheme && styles.childTheme)}
				padding="none"
			>
				{image?.filename && (
					<div
						className={cls(styles.image, styles[`image-height--${nrOfCards > 4 ? 4 : nrOfCards}`])}
						onClick={() => setShowVideo(true)}
						role="button"
						tabIndex={0}
						aria-label={t`Spela upp video`}
					>
						<ImageAsset absolute asset={image} objectFit="cover" fill objectPosition="center center" />
						<Icon name="play" size="4x" className={styles.playIcon} color="--text-light" />
						<div className={styles.imageOverlay} />
					</div>
				)}

				<div className={styles.textContainer}>
					<Heading as="div" size="h6" title={title} />

					{text && <Text marginBottom="xl">{text}</Text>}

					<div className={styles.buttonContainer}>
						<Button
							onClick={() => {
								setShowVideo(true);
								setTargetInteraction({ contentTarget: t`Spela upp video` });
							}}
							variant="text"
						>{t`Spela upp video`}</Button>
						{linkButton && (
							<LinkButton
								{...linkButton}
								variant="text"
								onClick={() => {
									setTargetInteraction({ contentTarget: linkButton.children as string });
								}}
							/>
						)}
					</div>
				</div>
			</Card>
		</>
	);
};

CardMediumVideo.blokProps = blokProps;
