import { Card, Heading, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton } from '@/bloks/Button';
import { CardSupportProfileStoryblok, LinkButtonStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ThemeProvider } from '@/contexts/theme';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import { cls } from '@/utils';
import React from 'react';
import styles from './CardSupportProfile.module.scss';

interface BlokProps {
	blok: CardSupportProfileStoryblok;
}

const blokProps = ({ blok }: BlokProps): CardSupportProfileProps => ({
	header: blok.header,
	text: blok.text,
	greeting: blok.greeting,
	image: blok.image,
	contact: blok.contact,
});

export interface CardSupportProfileProps {
	header: string;
	text: string;
	greeting?: string;
	image: ImageAssetProps;
	contact?: LinkButtonStoryblok[];
}

export const CardSupportProfile: Blok<CardSupportProfileProps, BlokProps> = ({
	header,
	text,
	greeting,
	image,
	contact,
}) => {
	const { hasSubMenuV1 } = useSubMenu();

	return (
		<ThemeProvider theme="support">
			<div className={cls(styles.container, hasSubMenuV1 && styles.hasSubMenuV1)}>
				<div className={styles.wrapper}>
					<Card className={cls(styles.verticalCard, styles.horizontalCard)}>
						<div className={styles.contentWrapper}>
							<Heading marginBottom="lg" as="div" size="h2" title={header} className={styles.heading} />
							<Text as="div" className={styles.text} size="default" marginBottom="lg">
								{text}
							</Text>
							<div className={styles.contactRow}>
								{contact?.map((blok) => {
									return (
										<LinkButton
											key={blok._uid}
											{...LinkButton.blokProps({ blok })}
											variant={'filled'}
											className={styles.button}
											size="large"
										/>
									);
								})}
								{greeting && (
									<Text as="div" className={styles.greeting} bold>
										{greeting}
									</Text>
								)}
							</div>
						</div>
					</Card>
					<div className={styles.imageBackground} />
					<div className={styles.imageWrapper}>
						<div className={styles.image}>
							<ImageAsset
								absolute
								objectFit="cover"
								objectPosition="center"
								resize="700x700"
								filters="smart"
								asset={image}
							/>
						</div>
					</div>
				</div>
			</div>
		</ThemeProvider>
	);
};

CardSupportProfile.blokProps = blokProps;
