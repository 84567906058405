import { Card, Heading } from '@/atoms';
import { useIntegration } from '@/contexts/Integration';
import React from 'react';
import styles from './UniqueSellingPoints.module.scss';

const UniqueSellingPoints: React.FC = () => {
	const { integration } = useIntegration();

	if (
		!integration?.uniqueSellingPoints?.points ||
		integration?.uniqueSellingPoints?.points?.every((usp) => usp.trim() === '')
	) {
		return null;
	}

	return (
		<Card className={styles.uniqueSellingPoints} padding="xl">
			<Heading as="h3" size="h4" title={integration?.uniqueSellingPoints.title ?? ''} />
			<ul>
				{integration?.uniqueSellingPoints?.points &&
					integration?.uniqueSellingPoints?.points
						.filter((point) => point)
						.map((point, index) => <li key={`point-${index}`}>{point}</li>)}
			</ul>
		</Card>
	);
};

export default UniqueSellingPoints;
