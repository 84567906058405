import { Heading } from '@/atoms';
import { LinkButton } from '@/bloks';
import { VideoButton } from '@/bloks/Button/VideoButton/VideoButton';
import { getTheme } from '@/bloks/Page/NavigationPageEducation/CardEducation/EducationTypeTheme';
import { CardEducationStoryblok } from '@/components';
import { EducationType } from '@/contexts/eduadmin';
import { ThemeProvider } from '@/contexts/theme';
import { cls, renderHtmlAsRichtext } from '@/utils';
import React from 'react';
import { t } from 'ttag';
import styles from './CardEducation.module.scss';

export const isPlayableVimeoVideo = (educationType: EducationType, vimeoId?: string) => {
	return educationType === EducationType.Recorded && vimeoId !== undefined && vimeoId !== '';
};

interface Props {
	blok: CardEducationStoryblok;
}

export const CardEducation: React.FC<Props> = ({ blok }) => {
	const { educationType, title, text, targetGroups, subjects, vimeoId, price, link } = blok;
	const themeInfo = getTheme(educationType as EducationType);
	const vimeoRecording = isPlayableVimeoVideo(educationType as EducationType, vimeoId);

	return (
		<div className={styles.container}>
			<ThemeProvider theme={themeInfo.name}>
				<header className={cls(styles.header, themeInfo.styles.bgColor)}>
					<Heading as="div" size="h6" marginBottom="none" title={educationType} />
				</header>
			</ThemeProvider>

			<Heading
				as="div"
				size="h5"
				marginBottom="none"
				title={title ?? ''}
				className={cls(styles.content, styles.title)}
			/>
			<div className={cls(styles.description, styles.content)}>{text && renderHtmlAsRichtext(text)}</div>
			<div className={cls(styles.contentList)}>
				<div className={styles.contentRow}>
					<div className={styles.contentHeader}>{t`Målgrupp`}</div>
					<div>{targetGroups && targetGroups.length > 0 ? targetGroups.join(', ') : '-'}</div>
				</div>
				<div className={styles.contentRow}>
					<div className={styles.contentHeader}>{t`Ämne`}</div>
					<div>{subjects && subjects.length > 0 ? subjects.join(', ') : '-'}</div>
				</div>
				<div className={styles.contentRow}>
					<div className={styles.contentHeader}>{t`Pris`}</div>
					<div>{price}</div>
				</div>
			</div>
			<div className={cls(styles.educationLink, styles.content)}>
				{vimeoRecording && link && link.length > 0 && (
					<LinkButton {...LinkButton.blokProps({ blok: link[0] })} variant={'text'} />
				)}
			</div>
			<footer className={styles.footer} data-testid="card-education-footer">
				{vimeoRecording && vimeoId ? (
					<VideoButton video={{ id: vimeoId }} buttonText={t`Spela upp utbildning`} />
				) : (
					link && link.length > 0 && <LinkButton {...LinkButton.blokProps({ blok: link[0] })} />
				)}
			</footer>
		</div>
	);
};
