import { Button, Divider, Grid, Heading, Modal, Section, Text } from '@/atoms';
import { CustomerRating, useIntegration } from '@/contexts/Integration';
import { formatLongDate } from '@/utils';
import { useEffect, useRef, useState } from 'react';
import { t } from 'ttag';
import styles from './CustomerRatings.module.scss';
import { IntegrationRating } from './IntegrationRating';
import Switch from './Switch';

const ReviewText: React.FC<{ review: string }> = ({ review }) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [isTruncated, setIsTruncated] = useState(false);

	const textRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (textRef.current) {
			setIsTruncated(textRef.current.scrollHeight > textRef.current.clientHeight);
		}
	}, [review]);

	return (
		<>
			<div ref={textRef} className={styles.review} style={{ WebkitLineClamp: isExpanded ? 'unset' : 4 }}>
				{review}
			</div>
			{isTruncated && (
				<Button variant="link" onClick={() => setIsExpanded(!isExpanded)}>
					{isExpanded ? t`Visa mindre` : t`Visa mer`}
				</Button>
			)}
		</>
	);
};

export const CustomerRatings: React.FC = () => {
	const { integration, ratings } = useIntegration();
	const [showModal, setShowModal] = useState(false);
	const [filterReviews, setFilterReviews] = useState(false);

	const onClose = () => {
		setShowModal(false);
	};

	if (!ratings?.length || !integration) return null;

	const employeeRanges: Record<string, string> = {
		ZERO: t`Företag utan anställda`,
		ONE_TO_FOUR: t`Företag med 1-4 anställda`,
		FIVE_TO_NINE: t`Företag med 5-9 anställda`,
		TEN_TO_NINETEEN: t`Företag med 10-19 anställda`,
		TWENTY_TO_FORTY_NINE: t`Företag med 20-49 anställda`,
		FIFTY_TO_NINETY_NINE: t`Företag med 50-99 anställda`,
		HUNDRED_OR_MORE: t`Företag med 100 eller fler anställda`,
		UNKNOWN: t`Företag utan uppgift om antal anställda`,
	};

	return (
		<Section paddingX={{ base: 'none' }} paddingY={{ base: 'xl' }} id="customerReviews">
			<Heading as="h3" title={t`Betyg på ${integration.appName}`} className={styles.reviewHeading} />
			<Grid columns={{ base: 1, xl: 3 }} colGap="3xl" rowGap="xl">
				{ratings.slice(0, 3).map((rating: CustomerRating) => {
					return (
						<Grid.Item key={`customerReview-${rating.ratingId}`}>
							<div className={styles.reviewItem}>
								<IntegrationRating rating={rating.rating} />
								<div className={styles.companySize}>{employeeRanges[rating.employeeRange]}</div>
								<ReviewText review={rating.comment} />
								<div className={styles.reviewDate}>{formatLongDate(rating.created?.split('T')[0])}</div>
							</div>
						</Grid.Item>
					);
				})}
				{ratings.length > 3 && (
					<Grid.Item colSpan={{ base: 1, xl: 3 }}>
						<Button variant="text" onClick={() => setShowModal(true)}>{t`Visa fler omdömen`}</Button>
					</Grid.Item>
				)}
			</Grid>

			<Modal className={styles.modal} show={showModal} onClose={onClose}>
				<Heading as="h3" title={t`Betyg på ${integration.appName}`} className={styles.reviewHeading} />
				<Switch
					label={t`Visa bara betyg som har omdömen`}
					onValueChange={() => setFilterReviews(!filterReviews)}
					testID="switch"
					value={filterReviews}
				/>
				{ratings
					.filter((rating: CustomerRating) => !filterReviews || rating.comment !== '')
					.map((rating: CustomerRating) => {
						return (
							<div key={`customerReview-${rating.ratingId}`} className={styles.modalReviewItem}>
								<Divider margin="lg" color="--line-color" />
								<IntegrationRating rating={rating.rating} />
								<div className={styles.companySize}>{employeeRanges[rating.employeeRange]}</div>
								<ReviewText review={rating.comment} />
								<div className={styles.reviewDate}>{formatLongDate(rating.created?.split('T')[0])}</div>
							</div>
						);
					})}
			</Modal>
		</Section>
	);
};
