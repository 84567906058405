import { Card, Heading, Icon, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { Integration, Integrator } from '@/contexts/Integration';
import { cls } from '@/utils';
import { t } from 'ttag';
import styles from './ContactInformation.module.scss';
import { ScopesTooltip } from './ScopesTooltip';

interface Props {
	integrator?: Integrator;
	showAccessRights?: boolean;
	integration?: Integration;
	className?: string;
}

const ContactInformation: React.FC<Props> = ({ integration, integrator, showAccessRights = true, className }) => {
	if (!integrator) return null;

	const { contactEmail, contactPhone, contactHomepage } = integrator;

	if (!contactEmail && !contactPhone && !contactHomepage) return null;

	function normalizeUrl(url: string): string {
		if (!/^https?:\/\//i.test(url)) {
			return `https://${url}`;
		}
		return url;
	}

	return (
		<Card padding="xl" className={cls(styles.contactInformation, className)}>
			{showAccessRights && (
				<>
					<Heading marginBottom="xs" as="h5" title={t`Har du frågor om ${integration?.appName}?`} />
					<Text size="small">{t`Kontakta supporten för att få hjälp.`}</Text>
				</>
			)}

			{contactHomepage && (
				<div className={styles.contactMethod}>
					<Icon name="globe" />
					<LinkButton href={normalizeUrl(contactHomepage)} variant="text" arrow={false}>
						{contactHomepage}
					</LinkButton>
				</div>
			)}
			{contactEmail && (
				<div className={styles.contactMethod}>
					<Icon name="envelope" />
					<LinkButton href={`mailto:${contactEmail}`} variant="text" arrow={false}>
						{contactEmail}
					</LinkButton>
				</div>
			)}
			{contactPhone && (
				<div className={styles.contactMethod}>
					<Icon name="phone" />
					<LinkButton href={`phone:${contactPhone}`} variant="text" arrow={false}>
						{contactPhone}
					</LinkButton>
				</div>
			)}
			{showAccessRights && (
				<div className={styles.showAccessRights}>
					<Heading as="h5" title={t`Behörigheter`} />
					<Text size="default">{t`För att olika funktioner i integrationen ska kunna fungera på ett visst sätt behöver integrationen åtkomst till ditt Fortnox-konto.`}</Text>
					<ScopesTooltip scopes={integration?.scopes} />
				</div>
			)}
		</Card>
	);
};

export default ContactInformation;
