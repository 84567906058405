import { Card, Heading, Icon, Link, Text } from '@/atoms';
import { Integrator } from '@/contexts/Integration';
import { useServerPageConfig } from '@/contexts/config';
import Image from 'next/image';
import React from 'react';
import { t } from 'ttag';
import styles from './IntegratorCard.module.scss';

interface Props {
	integrator: Integrator;
}

const IntegratorLogo: React.FC<Props> = ({ integrator }) => {
	const { appsHost: host } = useServerPageConfig();

	if (!integrator.companyLogoUrl) return null;

	return (
		<div className={styles.integratorLogo}>
			<Image
				src={`${host}${integrator.companyLogoUrl}`}
				alt={`${integrator.companyName} logotyp`}
				fill={false}
				width={800}
				height={800}
				className={styles.integratorLogo}
			/>
		</div>
	);
};
const About: React.FC<Props> = ({ integrator }) => {
	return (
		<div className={styles.integratorAbout}>
			<Heading as="h5" title={integrator.companyName} marginBottom="none" />

			{integrator.about && (
				<div className={styles.about}>
					<Text>{integrator.about}</Text>
					<Link href={`/integrationer/integration/${integrator.companyNameSlug}`} className={styles.readMore}>
						{t`Läs mer`}
					</Link>
				</div>
			)}
		</div>
	);
};
const ContactInfo: React.FC<Props> = ({ integrator }) => {
	if (!integrator.contactPhone && !integrator.contactEmail && !integrator.contactHomepage) return null;

	return (
		<div className={styles.integratorContact}>
			{integrator.contactPhone && (
				<div className={styles.integratorContactIcon}>
					<Icon name="phone" size="sm" />
					<span>{integrator.contactPhone}</span>
				</div>
			)}
			{integrator.contactEmail && (
				<div className={styles.integratorContactIcon}>
					<Icon name="envelope" size="sm" />
					<span>{integrator.contactEmail}</span>
				</div>
			)}
			{integrator.contactHomepage && (
				<div className={styles.integratorContactIcon}>
					<Icon name="desktop" size="sm" />
					<Link href={integrator.contactHomepage} target="_blank">
						{integrator.contactHomepage}
					</Link>
				</div>
			)}
		</div>
	);
};

const IntegratorInfo: React.FC<Props> = ({ integrator }) => {
	return (
		<div className={styles.integratorInfo}>
			<IntegratorLogo integrator={integrator} />
			<About integrator={integrator} />
			<ContactInfo integrator={integrator} />
		</div>
	);
};

export const IntegratorCard: React.FC<Props> = ({ integrator }) => {
	return (
		<Card border rounded className={styles.integratorCard}>
			<IntegratorInfo integrator={integrator} />
		</Card>
	);
};
