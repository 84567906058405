import { Card } from '@/atoms';
import { CardLeadsStoryblok, UpsalesFormStoryblok } from '@/components';
import { ThemeProvider } from '@/contexts/theme';
import { ISbStoryData } from '@/types/storyblok';
import { editableBlok } from '@/utils/storyblok';
import { useState } from 'react';
import { LinkButton } from '../Button';
import DynamicComponent from '../DynamicComponent';
import { UpsalesForm } from '../Form/UpsalesForm/UpsalesForm';
import common from './CardContact.module.scss';
import styles from './CardLeads.module.scss';

interface Props {
	blok: CardLeadsStoryblok;
	meta?: { zone?: number | undefined };
}

/**
 * Card with form to collect leads to upsales
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=94%3A3501
 *
 */
export const CardLeads: React.FC<Props> = ({ blok, meta }) => {
	const { header, link } = blok;
	const form = blok?.form as unknown as ISbStoryData<UpsalesFormStoryblok>;
	const [showText, setShowText] = useState(true);

	return (
		<ThemeProvider theme="lightGreen">
			<Card className={styles.card} rounded>
				<div {...editableBlok(blok)} className={common.container} data-testid="card-leads-container">
					{showText &&
						header &&
						header.map((head) => {
							return <DynamicComponent key={head._uid} blok={head} />;
						})}
					{form && (
						<UpsalesForm
							{...UpsalesForm.blokProps({
								story: form,
								meta: {
									...meta,
									theme: 'lightGreen',
									layout: blok?.formLayout,
									afterSubmit: () => setShowText(false),
								},
							})}
						/>
					)}
					{link && link?.map((button) => <LinkButton key={button._uid} {...LinkButton.blokProps({ blok: button })} />)}
				</div>
			</Card>
		</ThemeProvider>
	);
};
