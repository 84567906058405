import { Button, Heading, Text } from '@/atoms';
import { Event, Session } from '@/contexts/eduadmin';
import { formatDate, formatTime } from '@/utils/formatDate';
import { useRouter } from 'next/router';
import { t } from 'ttag';
import { LinkButton } from '../../Button';
import { TableBlok, TableCol, TableElem, TableHead, TableRow } from '../../RichText/TableBlok';
import styles from './EducationPage.module.scss';

const getTimeString = (startDate: string, endDate: string) =>
	`${formatTime(new Date(startDate))} - ${formatTime(new Date(endDate))}`;

const SessionHeader = (isRemote: boolean): TableHead[] => [
	{
		value: t`Datum`,
	},
	{
		value: t`Tid`,
	},
	{
		value: isRemote ? '' : t`Stad`,
	},
	{
		value: isRemote ? '' : t`Plats`,
	},
];

const SessionRows = (events: Event[], locale: string | undefined, translateToEnglish: boolean, isRemote: boolean) => {
	const sessionsAndEvent = events?.map((event) => {
		return { sessions: event.Sessions, event: event };
	});

	const sessionRows = sessionsAndEvent?.map((sessions) => {
		const rows = sessions.sessions?.map((session) => {
			const row: TableRow = {
				body: [
					{
						value: sessionDateRow(session),
					} as unknown as TableCol,
					{
						value: getTimeString(session.StartDate, session.EndDate),
					} as unknown as TableCol,
					{
						value: isRemote ? '' : sessions.event.City || '',
					} as unknown as TableCol,
					{ value: isRemote ? '' : sessions.event.AddressName || '' } as unknown as TableCol,
				],
			};

			return row;
		});

		const priceAndSignUpRow = {
			body: [
				{
					value: priceRowWithCourseName(sessions.event),
				} as unknown as TableCol,
				{
					value: '',
				} as unknown as TableCol,
				{
					value: '',
				} as unknown as TableCol,
				{
					value: signUpButtonRow(sessions.event, locale, translateToEnglish),
				} as unknown as TableCol,
			],
			className: styles.priceRow,
		};

		return [...rows, priceAndSignUpRow];
	});

	return sessionRows.flatMap((r) => r);
};

const EventRows = (events: Event[], locale: string | undefined, translateToEnglish: boolean, isRemote: boolean) => {
	return events?.map((event) => {
		const row: TableRow = {
			body: [
				{
					value: eventDateRow(event),
				} as unknown as TableCol,
				{
					value: getTimeString(event.StartDate, event.EndDate),
				} as unknown as TableCol,
				{
					value: isRemote ? '' : event.City,
				} as unknown as TableCol,
				{ value: isRemote ? '' : event.AddressName } as unknown as TableCol,
				{
					value: priceRow(event),
				} as unknown as TableCol,
				{
					value: signUpButtonRow(event, locale, translateToEnglish),
				} as unknown as TableCol,
			],
		};

		return row;
	});
};

const EventHeader = (isRemote: boolean): TableHead[] => [
	{
		value: t`Datum`,
	},
	{
		value: t`Tid`,
	},
	{
		value: isRemote ? '' : t`Stad`,
	},
	{
		value: isRemote ? '' : t`Plats`,
	},
	{
		value: t`Pris`,
	},
	{
		value: '',
	},
];

interface Props {
	events: Event[];
	translateToEnglish?: boolean;
	isRemote?: boolean;
}

export const EducationEventTable: React.FC<Props> = ({ events, translateToEnglish = false, isRemote = false }) => {
	const router = useRouter();
	const locale = router?.locale;
	const hasSessions = events.filter((event) => (event?.Sessions?.length > 0 ? true : false))?.some(Boolean);

	const tableBlok: TableElem = {
		value: {
			tbody: hasSessions
				? SessionRows(events, locale, translateToEnglish, isRemote)
				: EventRows(events, locale, translateToEnglish, isRemote),
			thead: hasSessions ? SessionHeader(isRemote) : EventHeader(isRemote),
			fieldtype: 'table',
		},
	};

	return (
		<div className={styles.educationTableContainer}>
			<TableBlok
				table={tableBlok}
				theme="white"
				leftTdIsLabel={false}
				className={hasSessions ? 'educationSessionTable' : 'educationEventTable'}
			/>
		</div>
	);
};

export const priceRowWithCourseName = (event: Event) => {
	const price = (event.PriceNames.find((p) => p.Price)?.Price || 0) + t` kr`;
	return (
		<div className={styles.containerInsideTd}>
			{event.OnDemand === false && (
				<Heading as="div" size="h6" className={styles.priceHeader} title={`${event.CourseName} `} marginBottom="none" />
			)}
			{event.OnDemand === true && <Text bold>{t`Inspelad variant - se när du vill under 30 dagar!`}</Text>}
			<Text>
				{t` Pris: `}
				{price}
			</Text>
		</div>
	);
};

const priceRow = (event: Event) => {
	const price = (event.PriceNames.find((p) => p.Price)?.Price || 0) + t` kr`;
	return <div>{price}</div>;
};

export const signUpButtonRow = (event: Event, locale: string | undefined, translateToEnglish: boolean) => {
	const bookedUp = event?.ParticipantNumberLeft != null && event.ParticipantNumberLeft < 1;
	const text = bookedUp ? t`Fullbokad` : t`Anmälan`;
	let url =
		locale === 'en' || translateToEnglish
			? `/en/utbildning/${event.CourseTemplateId}`
			: `/utbildning/${event.CourseTemplateId}`;
	if (!bookedUp) {
		url += '/anmalan/' + event.EventId;
	}

	return bookedUp ? (
		<Button disabled={bookedUp}>{text}</Button>
	) : (
		<LinkButton aria-label={t`Boka`} href={url}>
			{text}
		</LinkButton>
	);
};

const eventDateRow = (event: Event) => {
	const oneDayEvent = formatDate(event.StartDate) === formatDate(event.EndDate);
	const timeString = oneDayEvent ? formatDate(event.StartDate) : getTimeString(event.StartDate, event.EndDate);

	return <>{timeString}</>;
};

const sessionDateRow = (session: Session) => {
	return (
		<div className={styles.containerInsideTd}>
			<Heading as="div" size="h6" title={session.SessionName} />
			<Text>{formatDate(session.StartDate)}</Text>
		</div>
	);
};
