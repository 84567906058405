import { BrandLine, ContainerFluid, Heading, Section } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { CardSmallGroup, CardSmallGroupProps } from '@/bloks/Card/CardSmallGroup/CardSmallGroup';
import { renderRichText } from '@/bloks/RichText';
import { HeaderNavigationStoryblok, ThemeV2Storyblok } from '@/components';
import { ThemeProvider } from '@/contexts/theme';
import React from 'react';
import styles from './HeaderNavigation.module.scss';

interface BlokProps {
	blok: HeaderNavigationStoryblok;
}

interface HeaderNavigationProps {
	smallTitle?: string;
	title: string;
	shortText?: any;
	cardGroup?: CardSmallGroupProps;
	theme?: ThemeV2Storyblok['theme'] | undefined;
	backgroundPattern?: boolean;
}

const blokProps = ({ blok }: BlokProps): HeaderNavigationProps => ({
	smallTitle: blok.smallTitle,
	title: blok.title,
	shortText: blok.shortText,
	cardGroup: blok.cards?.[0] && CardSmallGroup.blokProps({ blok: blok.cards[0] }),
	theme: blok.theme?.[0]?.theme,
	backgroundPattern: blok.backgroundPattern,
});

export const HeaderNavigation: Blok<HeaderNavigationProps, BlokProps> = ({
	smallTitle,
	title,
	shortText,
	cardGroup,
	theme = 'darkGreen',
	backgroundPattern,
}) => {
	return (
		<ContainerFluid theme={theme} backgroundPattern={backgroundPattern}>
			<Section as="header">
				<div className={styles.textWrapper}>
					<BrandLine marginBottom="xl" />
					{smallTitle && <Heading as="div" size="h6" title={smallTitle} marginBottom="xl" />}
					<Heading as="h1" title={title} marginBottom="xl" />
					{shortText && renderRichText(shortText, { textSize: 'ingress', textMarginBottom: 'none' })}
				</div>
				{cardGroup && (
					<ThemeProvider theme={theme} applyChildTheme>
						<CardSmallGroup {...cardGroup} gapSize="sm" borderRadius="3-5" />
					</ThemeProvider>
				)}
			</Section>
		</ContainerFluid>
	);
};

HeaderNavigation.blokProps = blokProps;
