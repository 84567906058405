import { Button, TextField } from '@/atoms';
import { useSearchV2 } from '@/contexts/search/AddSearchProviderV2';
import { useKeyPress } from '@/hooks/useKeyPress';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { cls } from '@/utils';
import { useEffect, useRef, useState } from 'react';
import { t } from 'ttag';
import styles from './SearchField.module.scss';

interface Props {
	compact?: boolean;
	className?: string;
}

export const SearchField: React.FC<Props> = ({ compact, className }) => {
	const { isSearchOpen, setSearchOpen, searchTerm, setSearchTerm, suggestions, search, setAnimation } = useSearchV2();
	const [showSuggestions, setShowSuggestions] = useState(false);
	const searchFieldRef = useRef<HTMLInputElement>(null);
	const suggestionsRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (searchFieldRef.current && searchFieldRef.current === document.activeElement) {
			setShowSuggestions(true);
		} else {
			setShowSuggestions(false);
		}
	}, [searchTerm]);

	useOnClickOutside(suggestionsRef, () => setShowSuggestions(false));

	const openSearch = () => {
		if (!isSearchOpen) {
			setSearchOpen(true);
			setAnimation('slideIn');
		}
	};

	useKeyPress('Enter', undefined, () => {
		openSearch();
		setShowSuggestions(false);
		search();
	});

	const handleSearchBtn = () => {
		openSearch();
		search();
	};

	const handleClickOnSuggestion = (suggestionValue: string) => {
		openSearch();
		setSearchTerm(suggestionValue);
		setShowSuggestions(false);
		search();
	};

	return (
		<div className={cls(styles.container, className)}>
			<div className={styles.searchFieldWrapper}>
				{showSuggestions && !!suggestions.length && (
					<div className={styles.suggestions} ref={suggestionsRef}>
						{suggestions.map((suggestion: any) => (
							<Button
								key={suggestion.value}
								variant="link"
								onClick={() => handleClickOnSuggestion(suggestion.value)}
								type="button"
								tabIndex={showSuggestions ? 0 : -1}
							>
								{suggestion.value}
							</Button>
						))}
					</div>
				)}
				<div className={styles.textField}>
					<TextField
						type="search"
						placeholder={t`Vad letar du efter?`}
						borderRadius="--border-radius-2"
						bordered
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						forrwardedRef={searchFieldRef}
						compact={compact}
					/>
				</div>
			</div>
			<div className={styles.desktop}>
				<Button variant="outlined" onClick={handleSearchBtn} size="small" type="button" block className={styles.btn}>
					{t`Sök`}
				</Button>
			</div>
		</div>
	);
};
