import { Icon } from '@/atoms';
import React from 'react';
import styles from './IntegrationRating.module.scss';

interface Props {
	rating: number | null;
}

const getColor = (component: number, rating: number | null) => {
	return Math.round(rating || 0) >= component ? '--primary-yellow' : '--border-color';
};

const getType = (component: number, rating: number | null) => {
	return Math.round(rating || 0) >= component ? 'solid' : 'light';
};

export const IntegrationRating: React.FC<Props> = ({ rating = 0 }) => {
	const renderStars = () => {
		const stars = [];
		for (let i = 1; i <= 5; i++) {
			stars.push(
				<Icon
					key={`rating-${i}`}
					name="star"
					type={getType(i, rating)}
					color={getColor(i, rating)}
					aria-label={`rating-${i}`}
					testID={`rating-${i}`}
				/>,
			);
		}
		return stars;
	};

	return (
		<div className={styles.integrationRating} data-test-id="app-rating">
			{renderStars()}
		</div>
	);
};
