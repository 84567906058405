import { Card, Heading, Image } from '@/atoms';
import { AccountantChips } from '@/bloks/Page/NavigationPageFindAccountants/AccountantCard/AccountantChips';
import { ContactInfo } from '@/bloks/Page/NavigationPageFindAccountants/AccountantCard/ContactInfo';
import { ExpandableDescription } from '@/bloks/Page/NavigationPageFindAccountants/AccountantCard/ExpandableDescription';
import { Choice } from '@/bloks/Page/NavigationPageFindAccountants/MultiChoiceFilter/MultiChoiceFilter';
import { Accountant } from '@/contexts/accountants/types';
import { useServerPageConfig } from '@/contexts/config';
import React, { useState } from 'react';
import styles from './AccountantCard.module.scss';

interface Props {
	accountant: Accountant;
	filterChoices: Choice[];
}

export const AccountantCard: React.FC<Props> = ({ accountant, filterChoices }) => {
	const [expandedDescription, setExpandedDescription] = useState<boolean>(false);
	const [logoExists, setLogoExists] = useState<boolean>(accountant.logoExists);
	const logoPath = `/api/digital-sales-bureau/searchable-bureaus-v1/logo/${accountant.id}`;
	const { appsHost: host } = useServerPageConfig();

	return (
		<Card className={styles.container} border>
			<header className={styles.header}>
				<Heading marginBottom="none" title={accountant.companyName} as="div" size="h5" />
			</header>
			<AccountantChips description={accountant.description} filterChoices={filterChoices} />
			<ExpandableDescription
				text={accountant.description}
				maxCharacters={150}
				maxParagraphs={3}
				expanded={expandedDescription}
				setExpanded={setExpandedDescription}
			/>
			<ContactInfo accountant={accountant} show={!expandedDescription} />
			<div className={styles.footer}>
				<div>
					{accountant.certified && (
						<Image
							src={'/fortnox-certified-bureau.png'}
							alt={'Fortnox Certifierad byrå'}
							title={'Fortnox Certifierad byrå'}
							loading={'lazy'}
							width={70}
							height={70}
						/>
					)}
				</div>
				<div className={styles.logoContainer}>
					{logoExists && (
						<Image
							src={`${host}${logoPath}`}
							alt={''}
							className={styles.logo}
							onError={() => setLogoExists(false)}
							width={45}
							height={45}
							layout="responsive"
							objectFit="contain"
						/>
					)}
				</div>
			</div>
		</Card>
	);
};
