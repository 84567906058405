import { Icon } from '@/atoms';
import { Integration, IntegrationTransform } from '@/contexts/Integration';
import { useScrollTo } from '@/hooks/useScrollTo';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { t } from 'ttag';
import styles from '../IntegrationCard.module.scss';

interface IntegrationProps {
	integration: Integration | IntegrationTransform;
	iconSize?: SizeProp;
}

const mapRangeLabel = (rangeLabel: string) => rangeLabel.substring(8);

export const UserInsights: React.FC<IntegrationProps> = ({ integration, iconSize = 'sm' }) => {
	const scrollTo = useScrollTo();

	return (
		<>
			<div className={styles.ratings} onClick={() => scrollTo('customerReviews', 100)}>
				<Icon name={'star'} size={iconSize} className={styles.icon} />
				{integration.ratings.averageScore.toFixed(1)}
				<small>({t`${integration.ratings.numberOfRatings} st`})</small>
			</div>

			<div className={styles.sizeOfCompany}>
				<Icon name={'users'} size={iconSize} className={styles.icon} />
				<span data-cy-type={integration.insights.numberOfUsersRange}>
					{mapRangeLabel(integration.insights.numberOfUsersRange)} {t`företag använder`}
				</span>
			</div>
		</>
	);
};
