import { Card, Heading, Icon, IconPlus, IconProps, Link, Text } from '@/atoms';
import { LinkButton, LinkButtonProps, buildLinkProps } from '@/bloks/Button/LinkButton';
import { renderRichText } from '@/bloks/RichText';
import { CardSmallIconGroupStoryblok, CardSmallIconStoryblok } from '@/components';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { useThemeContext } from '@/contexts/theme';
import { cls, isRichtextEmpty } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { IconPlusName } from 'icons';
import styles from './Common.module.scss';

interface BlokProps {
	blok: CardSmallIconStoryblok;
	meta?: {
		buttonVariant?: CardSmallIconProps['buttonVariant'];
		padding?: boolean;
		opacity?: boolean;
		titleSize: CardSmallIconGroupStoryblok['titleSize'];
	};
}

export type CardSmallIconMeta = BlokProps['meta'];

const blokProps = ({ blok, meta }: BlokProps): CardSmallIconProps => ({
	iconName: blok.icon?.[0]?.name ?? 'check',
	title: blok.title,
	titleSize: meta?.titleSize ?? 'h5',
	text: blok.text,
	linkButton: blok.linkButton?.[0]?.link && LinkButton.blokProps({ blok: blok.linkButton[0] }),
	buttonVariant: meta?.buttonVariant ?? 'filled',
	padding: meta?.padding,
	opacity: meta?.opacity,
	cardLink: (blok?.cardLink?.url || blok?.cardLink?.cached_url) && buildLinkProps(blok.cardLink).href,
	_editable: blok._editable,
});
export interface CardSmallIconProps {
	iconName: IconProps['name'];
	title: string;
	titleSize?: CardSmallIconGroupStoryblok['titleSize'];
	text: string | any;
	linkButton?: LinkButtonProps;
	padding?: boolean;
	opacity?: boolean;
	buttonVariant?: 'filled' | 'outlined' | 'text';
	className?: string;
	cardLink?: string;
	_editable?: string;
}

/**
 * @deprecated Will be replaced by CardSmall
 *
 * A small Card with icon, title, text and button
 *
 * Used in CardSmallIconGroup which can be inserted into SectionCard and SectionCardImage
 */
export function CardSmallIcon({
	iconName,
	title,
	titleSize = 'h5',
	text,
	linkButton,
	padding = false,
	opacity = false,
	buttonVariant = 'filled',
	className,
	cardLink,
	_editable,
}: CardSmallIconProps) {
	const { applyChildTheme } = useThemeContext();
	const isIconPlus = iconName.startsWith('plus_');
	const isTextEmpty = (typeof text === 'string' && !text) || (typeof text !== 'string' && isRichtextEmpty(text));
	const { setTargetInteraction } = useImpressionTracking();

	const card = (
		<Card
			className={cls(
				styles.card,
				(padding || applyChildTheme) && styles.padding,
				styles[`layout--center`],
				cardLink && styles.cardLink,
				opacity && styles.opacity,
				className,
			)}
			padding="custom"
			layout="center"
			{...editableBlok({ _editable })}
		>
			<div className={cls(styles.headerIconWrapper, isTextEmpty && styles.noText)}>
				<div className={styles.iconWrapper}>
					{iconName &&
						(isIconPlus ? (
							<IconPlus name={iconName as IconPlusName} size="lg" />
						) : (
							<Icon name={iconName} className={styles.icon} />
						))}
				</div>
				<Heading as="div" size={titleSize} title={title} marginBottom="custom" />
			</div>
			{!isTextEmpty && (
				<Text marginBottom="2xl" as="div">
					{typeof text === 'string' ? text : renderRichText(text)}
				</Text>
			)}
			{!cardLink && (
				<div className={styles.btnWrapper}>
					{linkButton && (
						<LinkButton
							{...linkButton}
							variant={buttonVariant}
							className={styles.btn}
							onClick={() => {
								setTargetInteraction({ contentTarget: linkButton.children as string });
							}}
						/>
					)}
				</div>
			)}
		</Card>
	);

	return cardLink ? <Link href={cardLink}>{card}</Link> : card;
}

CardSmallIcon.blokProps = blokProps;
