import { Card, Heading, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { DynamicLinkButton } from '@/bloks/DynamicLinkButton';
import { renderRichText } from '@/bloks/RichText';
import {
	CardSquareStoryblok,
	LinkButtonStoryblok,
	LinkButtonWithLabelStoryblok,
	RichtextStoryblok,
	ThemeV2Storyblok,
} from '@/components';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ImageAsset, ImageAssetProps } from '@/metabloks/ImageAsset';
import { getThemeInfo } from '@/themes';
import { getPiwikTrackingSlug } from '@/trackers/piwik';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { usePiwikPro } from '@piwikpro/next-piwik-pro';
import { usePathname } from 'next/navigation';
import styles from './CardSquare.module.scss';

interface BlokProps {
	blok: CardSquareStoryblok;
	meta?: {
		theme?: ThemeV2Storyblok['theme'];
	};
}

const blokProps = ({ blok, meta }: BlokProps): CardSquareProps => ({
	title: blok.title,
	text: blok.text,
	linkButtons: blok.linkButtons,
	image: blok.image,
	_editable: blok._editable,
	theme: meta?.theme,
	_uid: blok._uid,
});

export interface CardSquareProps {
	title: string;
	text: RichtextStoryblok | string;
	linkButtons?: (LinkButtonStoryblok | LinkButtonWithLabelStoryblok)[];
	image?: ImageAssetProps;
	_editable?: string;
	theme?: ThemeV2Storyblok['theme'];
	className?: string;
	zone?: number;
	_uid: string;
}

export const CardSquare: Blok<CardSquareProps, BlokProps> = ({
	title,
	text,
	linkButtons,
	image,
	_editable,
	theme,
	className,
	zone,
}) => {
	const themeInfo = getThemeInfo(theme);

	// PIWIK IMPRESSION TRACKING
	const { ContentTracking, DataLayer } = usePiwikPro();
	const pathname = usePathname();
	const slug = getPiwikTrackingSlug(pathname);
	const contentName = `${zone}|SectionSquareCards`;
	const contentPiece = title;
	let contentTarget = linkButtons && linkButtons.length ? 'multiple interactions possible' : 'no interactions possible';
	if (linkButtons?.length === 1) contentTarget = 'one interaction possible';

	function trackContentInteraction(targetText?: string) {
		DataLayer.push({
			event: 'contentInteraction',
			contentName: `${slug}|${contentName}`,
			contentPiece,
			contentTarget,
			contentInteraction: 'Click',
			data: { clickElementText: targetText },
		});

		ContentTracking.trackContentInteraction(
			'contentInteraction',
			`${slug}|${contentName}`,
			contentPiece,
			contentTarget,
		);
	}

	return (
		<ImpressionTrackingProvider
			value={{ contentName: `${zone}|SectionSquareCards`, contentPiece: title, contentTarget }}
		>
			<Card
				{...editableBlok({ _editable })}
				className={cls(themeInfo?.styles.bgColor, styles.card, className)}
				padding="custom"
				rounded={false}
			>
				<div className={styles.headingWrapper}>
					<Heading
						as="div"
						size="h2"
						title={title}
						marginBottom="xl"
						className={cls(themeInfo?.styles.titleColor, styles.heading)}
					/>
					{image?.filename && (
						<div className={styles.imageWrapper}>
							<ImageAsset resize="214x214" asset={image} />
						</div>
					)}
				</div>
				<Text as="div" marginBottom="xl">
					{renderRichText(text)}
				</Text>
				<div>
					{linkButtons?.map((button) => (
						<div
							key={button._uid}
							className={styles.linkWrapper}
							onClick={() => {
								trackContentInteraction(
									button.component === 'LinkButtonWithLabel' ? button.linkButton[0].text : button.text,
								);
							}}
						>
							<DynamicLinkButton blok={button} meta={{ variant: 'text', theme }} />
						</div>
					))}
				</div>
			</Card>
		</ImpressionTrackingProvider>
	);
};

CardSquare.blokProps = blokProps;
