import { useState } from 'react';
import styles from './Switch.module.scss';

interface SwitchProps {
	label: string;
	onValueChange: (checked: boolean) => void;
	testID?: string;
	value: boolean;
}

export default function Switch({ label, onValueChange, testID, value }: SwitchProps) {
	const [checked, setChecked] = useState(value);

	const handleChange = (state: boolean) => {
		setChecked(!checked);
		onValueChange(state);
	};

	return (
		<label className={styles.switchContainer}>
			<span>{label}</span>
			<input
				type="checkbox"
				checked={checked}
				onChange={(e) => handleChange(checked)}
				data-testid={testID}
				className={styles.switchInput}
			/>
			<span className={styles.switchSlider}></span>
		</label>
	);
}
