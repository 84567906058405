import { BrandStripes, Card, ContainerFluid, Grid, Heading, Link, PageLayout, Section, Text } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { NavigationPageIntegrationDynamicStoryblok } from '@/components';
import { Integration, IntegrationCategory, Integrator, useIntegrationCategories } from '@/contexts/Integration';
import { useIntegrator } from '@/contexts/Integration/IntegratorProvider';
import { editableBlok } from '@/utils/storyblok';
import { IconName } from 'icons/types';
import { NextSeo } from 'next-seo';
import React from 'react';
import { jt, t } from 'ttag';
import { calculateIntegratorScore } from '../IntegrationPage/functions/calculateIntegratorScore';
import { CategoryIcons } from './CategoryIcons';
import { IntegrationCard } from './IntegrationCard/IntegrationCard';
import { IntegrationCategoryCard } from './IntegrationCategoryCard/IntegrationCategoryCard';
import { IntegratorCard } from './IntegratorCard/IntegratorCard';
import styles from './NavigationPageIntegrations.module.scss';

interface Props {
	blok: NavigationPageIntegrationDynamicStoryblok;
	meta: Record<string, any>;
}

export const getCategoryIcon = (id: number): IconName => {
	return (CategoryIcons.get(id) as IconName) ?? ('chart-mixed' as IconName);
};

const NoIntegrations = () => {
	const developerPartnerLink = <Link href="/kopplingar/konsulter">{t`utvecklingspartners`}</Link>;

	return (
		<Text
			className={styles.noIntegrations}
			as="p"
		>{jt`Integrationer saknas för denna kategori. Om du inte hittar en lösning som passar ditt företag
				kan du få hjälp med att bygga en. Kontakta någon av våra ${developerPartnerLink}.
				De hjälper dig att skapa en integrationslösning som passar just ditt företags behov.`}</Text>
	);
};

interface CategoriesProps {
	categories: IntegrationCategory[];
	header: any;
	blok: any;
}

const CategoriesList: React.FC<CategoriesProps> = ({ categories, header, blok }) => (
	<ContainerFluid>
		{header && <DynamicComponent key={blok._uid} blok={header} meta={{ zone: 1 }} />}
		<Section className={styles.container} paddingX={{ base: 'md', xl: '4xl' }} paddingY={{ base: 'xl' }}>
			<Grid columns={{ base: 1, sm: 2, lg: 3, max: 3 }} colGap="xl" rowGap="xl">
				{categories.map((category) => (
					<Grid.Item key={category.categoryId}>
						<IntegrationCategoryCard category={category} />
					</Grid.Item>
				))}
			</Grid>
		</Section>
	</ContainerFluid>
);

interface CategoryIntegrationProps {
	category: IntegrationCategory;
	integrations?: Integration[];
	blok: any;
	header: any;
}
const CategoryIntegrations: React.FC<CategoryIntegrationProps> = ({ category, integrations, blok, header }) => {
	return (
		<ContainerFluid>
			<NextSeo
				title={blok.meta[0].title ?? t`Integrationer för ${category.name} | Fortnox`}
				description={blok.meta[0].description ?? category.summary.substring(0, 160)}
			/>
			{header?.h1Part1 ? (
				<DynamicComponent key={blok._uid} blok={header} meta={{ zone: 1 }} />
			) : (
				<Section>
					<BrandStripes />
					<Heading as="h1" title={t`Integrationer för ${category.name}`} />
					<Text as="p">{category.description}</Text>
				</Section>
			)}

			<Section className={styles.container} paddingX={{ base: 'md', xl: '4xl' }} paddingY={{ base: 'xl' }}>
				{integrations?.length ? (
					<Grid columns={{ base: 1, sm: 2, lg: 3, max: 3 }} colGap="xl" rowGap="xl">
						{integrations.map((integration) => (
							<Grid.Item key={integration.appId}>
								<IntegrationCard integration={integration} />
							</Grid.Item>
						))}
					</Grid>
				) : (
					<NoIntegrations />
				)}
			</Section>

			{blok.testimonials?.map((blok: any) => <DynamicComponent key={blok._uid} blok={blok} />)}
		</ContainerFluid>
	);
};

interface IntegratorProps {
	integrators: Integrator[];
	header: any;
	blok: any;
}

const IntegratorsList: React.FC<IntegratorProps> = ({ integrators, header, blok }) => {
	const sortedIntegrators = (integrators: Integrator[]): Integrator[] =>
		integrators
			.sort((a, b) => a.companyName.localeCompare(b.companyName))
			.sort((a, b) => calculateIntegratorScore(b) - calculateIntegratorScore(a));

	return (
		<ContainerFluid>
			<Section paddingX={{ base: 'md', xl: '4xl' }} paddingY={{ base: '5xl' }} className={styles.companyHeader}>
				<BrandStripes />
				<Heading as="h1" title={t`Leverantörer `} />
			</Section>

			<Section className={styles.container} paddingX={{ base: 'md', xl: '4xl' }} paddingY={{ base: 'xl' }}>
				<Grid columns={{ base: 1, md: 2, lg: 3, max: 3 }} colGap="xl" rowGap="xl">
					{sortedIntegrators(integrators)
						.filter((i) => calculateIntegratorScore(i) > 0)
						.map((integrator) => (
							<Grid.Item key={integrator?.companyName}>
								<IntegratorCard integrator={integrator} />
							</Grid.Item>
						))}
				</Grid>
			</Section>

			<Section className={styles.container} paddingX={{ base: 'md', xl: '4xl' }} paddingY={{ base: 'xl', md: '4xl' }}>
				<Heading as="h2" title={t`Fler leverantörer`} marginBottom="xl" />
				<Grid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} colGap="xl" rowGap="xl">
					{integrators
						.filter((i) => calculateIntegratorScore(i) <= 0)
						.sort((a, b) => a.companyName.localeCompare(b.companyName))
						.map((integrator) => (
							<Grid.Item key={integrator?.companyName}>
								<Card border rounded padding="lg" cardLink={`/integrationer/integration/${integrator.companyNameSlug}`}>
									{integrator?.companyName}
								</Card>
							</Grid.Item>
						))}
				</Grid>
			</Section>
		</ContainerFluid>
	);
};

const ErrorMessage = () => (
	<ContainerFluid>
		<Section className={styles.notFoundText}>
			<Text>{t`Tyvärr hittade vi inget som matchar din sökning.`}</Text>
		</Section>
	</ContainerFluid>
);
export const NavigationPageIntegrationDynamic: React.FC<Props> = (props) => {
	const { blok, meta } = props;

	const header = Array.isArray(blok.header) ? blok.header[0] : blok.header;
	const { categories, integrations, category } = useIntegrationCategories();
	const { integrators } = useIntegrator();

	return (
		<PageLayout meta={meta}>
			<div {...editableBlok(blok)} className={styles.container}>
				{categories && !category && !integrations && (
					<CategoriesList categories={categories} header={header} blok={blok} />
				)}

				{category && (
					<CategoryIntegrations category={category} integrations={integrations} blok={blok} header={header} />
				)}

				{integrators && !integrations && !categories && (
					<IntegratorsList integrators={integrators} header={header} blok={blok} />
				)}

				{!integrators && categories && !categories.length && <ErrorMessage />}
			</div>
		</PageLayout>
	);
};
