import { useCallback } from 'react';

export const useScrollTo = () => {
	return useCallback((elementId: string, offset: number = 0) => {
		const element = document.getElementById(elementId);
		if (element) {
			const elementPosition = element.getBoundingClientRect().top + window.scrollY;
			window.scrollTo({ top: elementPosition - offset, behavior: 'smooth' });
		}
	}, []);
};
