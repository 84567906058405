import { BrandLine, Card, Heading, Icon, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, PhoneLink } from '@/bloks/Button';
import { CardProfileStoryblok, LinkButtonStoryblok, PhoneLinkStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import { getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import React from 'react';
import styles from './CardProfile.module.scss';

interface BlokProps {
	blok: CardProfileStoryblok;
	meta?: { zone?: number };
}

const blokProps = ({ blok, meta }: BlokProps): CardProfileProps => {
	const props = {
		image: blok.image,
		title: blok.title,
		subtitle: blok.subtitle,
		name: blok.name,
		phone: blok.phone,
		email: blok.email,
		contact: blok.contact,
	};

	if (meta) {
		Object.assign(props);
	}

	return props;
};

export interface CardProfileProps {
	image: ImageAssetProps;
	title: string;
	name: string;
	subtitle?: string;
	phone?: PhoneLinkStoryblok[];
	email: LinkButtonStoryblok['link'];
	contact?: LinkButtonStoryblok[];
}
/*
 * Used as a contact card of a person working at Fortnox
 */
export const CardProfile: Blok<CardProfileProps, BlokProps> = ({
	image,
	title,
	name,
	subtitle,
	phone,
	email,
	contact,
}) => {
	const { hasSubMenuV1 } = useSubMenu();
	const { setTargetInteraction } = useImpressionTracking();
	const themeInfo = getThemeInfo('lightBeige');

	return (
		<div className={cls(hasSubMenuV1 && styles.hasSubMenuV1)}>
			<div className={styles.titleWrapper}>
				<BrandLine className={styles.brandLine} />
				{title && (
					<Heading as="h2" size="h4" title={title} marginBottom="xl" data-testid="card-small-profile-header-h4" />
				)}
			</div>
			<div className={styles.wrapper}>
				<div
					className={cls(styles.backgroundFill, themeInfo.styles.bgColor, themeInfo.name === 'white' && styles.border)}
				/>
				<div className={styles.image}>
					<ImageAsset
						absolute
						objectFit="contain"
						objectPosition="center"
						resize="330x330"
						filters="smart"
						asset={image}
					/>
				</div>
				<div className={styles.contentWrapper}>
					<Card className={styles.card} noBackground>
						<Heading marginBottom="none" as="div" size="h5" title={name} />
						{subtitle && <Text as="div">{subtitle}</Text>}

						<ul className={styles.contactList}>
							{phone?.map((blok) => (
								<li key={blok._uid} className={styles.contactItem}>
									<Icon name="phone" className={styles.icon} />
									<PhoneLink
										{...PhoneLink.blokProps({ blok })}
										onClick={() => {
											setTargetInteraction({ contentTarget: 'Telefonnummer' });
										}}
									/>
								</li>
							))}

							<li className={styles.contactItem}>
								<Icon name="envelope" className={styles.icon} />
								<LinkButton
									href={email.email}
									type="email"
									variant="text"
									arrow={false}
									onClick={() => {
										setTargetInteraction({ contentTarget: 'E-post' });
									}}
								>
									{email.email}
								</LinkButton>
							</li>
							{contact?.map((blok) => (
								<li key={blok._uid} className={styles.contactItem}>
									<Icon name="headset" className={styles.icon} />
									<LinkButton
										{...LinkButton.blokProps({ blok })}
										variant="text"
										onClick={() => {
											setTargetInteraction({ contentTarget: blok.text ?? '' });
										}}
									/>
								</li>
							))}
						</ul>
					</Card>
				</div>
			</div>
		</div>
	);
};

CardProfile.blokProps = blokProps;
