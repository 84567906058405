import { Card, Chip, Heading } from '@/atoms';
import { useIntegration } from '@/contexts/Integration';
import React from 'react';
import styles from './TagCloud.module.scss';

const TagCloud: React.FC = () => {
	const { integration } = useIntegration();

	if (!integration?.tags?.length) return null;

	return (
		<Card className={styles.tags} padding="xl">
			<Heading as="h3" size="h4" title="Funktioner" />
			<div className={styles.tagsList}>
				{integration.tags.map((tag, index) => (
					<Chip key={`point-${index}`} text={tag} size="small" />
				))}
			</div>
		</Card>
	);
};

export default TagCloud;
