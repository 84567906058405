import { useTheme } from '@/contexts/theme';
import { getCorrespondingLegacyTheme } from '@/themes';
import { Themes } from '@/types';
import { cls } from '@/utils';
import { IconPlusName, allIconsPlus } from 'icons';
import { BaseIconProps } from '../Icon/Icon';
import styles from './IconPlus.module.scss';

interface IconPlusProps extends Omit<BaseIconProps, 'size' | 'name'> {
	/**
	 * Size of the icon
	 *
	 *		md	64px	4rem
	 *		lg	112px	7rem
	 *		xl	160px	10rem
	 *		xxl	240px	15rem
	 */
	size: 'md' | 'lg' | 'xl' | 'xxl';
	name: IconPlusName;
}

const getIconPlusTheme = (theme: Themes['theme']) => {
	const notDefaultTheme = ['liveGreen', 'yellow', 'purple'];

	if (!theme) {
		return 'default';
	}

	if (notDefaultTheme.includes(theme)) {
		return theme;
	}

	return 'default';
};

export const IconPlus: React.FC<IconPlusProps> = ({ className, name, size = 'md' }) => {
	const themeInfo = useTheme();
	const themeName = getIconPlusTheme(getCorrespondingLegacyTheme(themeInfo.name as Themes['theme']));
	const Component = allIconsPlus[name] as any;

	if (!Component) {
		console.error(`Icon "${name}" not found in allPlusIcons.`);
		return null;
	}

	return (
		<span className={cls(styles.container, styles[`size--${size}`], className)}>
			<Component className={`iconPlus theme--${themeName}`} />
		</span>
	);
};
