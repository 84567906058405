import { Card, Grid, Heading, Icon, Text } from '@/atoms';
import { IntegrationCategory } from '@/contexts/Integration';
import { slugifyName } from '@/utils';
import Link from 'next/link';
import { getCategoryIcon } from '../NavigationPageIntegrationDynamic';
import styles from './IntegrationCategoryCard.module.scss';

interface Props {
	category: IntegrationCategory;
}

export const IntegrationCategoryCard: React.FC<Props> = ({ category }) => {
	return (
		<Link href={`/integrationer/kategorier/${slugifyName(category.name).toLowerCase()}`}>
			<Card border rounded className={styles.integrationCategory}>
				<Grid columns={{ base: 4 }} colGap="lg">
					<Grid.Item colSpan={{ base: 4 }}>
						<Heading as="h5" title={category.name} />
					</Grid.Item>
					<Grid.Item colSpan={{ base: 3 }}>
						<Text as="p" className={styles.categoryDescription}>
							{category.description}
						</Text>
					</Grid.Item>
					<Grid.Item colSpan={{ base: 1 }}>
						<Icon name={getCategoryIcon(category.categoryId)} size="4x" />
					</Grid.Item>
				</Grid>
			</Card>
		</Link>
	);
};
