import { BrandStripes, Heading, PageLayout, Section, Text } from '@/atoms';
import { BookkeepingPostStoryblok, CardFiveGroupStoryblok, WordPostStoryblok } from '@/components';
import { ISbStoryData } from '@/types/storyblok';
import { isNotString } from '@/utils';
import Head from 'next/head';
import { StoryblokStory } from 'storyblok-generate-ts';
import { t } from 'ttag';
import DynamicComponent from '../DynamicComponent';
import { renderRichText } from '../RichText';
import styles from './WordPost.module.scss';

interface Props {
	blok: Omit<WordPostStoryblok | BookkeepingPostStoryblok, 'relatedPosts'> & {
		relatedPosts?: (string | StoryblokStory<WordPostStoryblok | BookkeepingPostStoryblok>)[];
	};
	meta: {
		[prop: string]: unknown;
	};
}

export const WordPost: React.FC<Props> = ({ blok, meta }) => {
	const { h1Part1, intro } = blok;
	const titlePrefix = blok.component === 'BookkeepingPost' ? t`Bokföra` : t`Vad är`;
	const titleSuffix = blok.component === 'WordPost' ? '?' : '';
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;
	const relatedPosts = blok.relatedPosts?.filter(isNotString) ?? [];

	return (
		<>
			<Head>
				<meta name="addsearch-custom-field" data-type="text" content={`word=${h1Part1}`} />
			</Head>
			<PageLayout exits={contact} meta={meta}>
				<Section paddingX={{ base: 'xl', md: '3xl', xl: 'none' }} maxWidth="925px" as="article">
					<BrandStripes />
					<div className={styles.intro}>
						{h1Part1 && (
							<Heading
								as="h1"
								className={styles.title}
								title={`${titlePrefix} ${h1Part1}${titleSuffix}`}
								marginBottom="xl"
							/>
						)}
						{intro && (
							<Text className={styles.intro} marginBottom="xl" size="ingress">
								{intro}
							</Text>
						)}
						{relatedPosts.length > 0 && (
							<div className={styles.relatedWords}>
								<Heading as="div" size="h4" title={t`Relaterade ord`} />
								<div className={styles.keyWords}>
									{relatedPosts.map((key, index) => (
										<Text key={index} bold>
											{key.name}
										</Text>
									))}
								</div>
							</div>
						)}
					</div>
					<div className={styles.content}>{renderRichText(blok.longText, { exo2: true })}</div>
				</Section>

				{blok.actions?.map((cards) => <DynamicComponent blok={cards} key={cards._uid} />)}
			</PageLayout>
		</>
	);
};
